import React, { useEffect, useState } from "react";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Qaha } from "../../Components/Qaha/Qaha";
import Cookies from "universal-cookie";
import { IoAddSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

export const Home = () => {
  const cookies = new Cookies();
  let userId = cookies.get("userId");
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchWajbaData();
  }, []);

  const fetchWajbaData = async () => {
    try {
      const response = await fetch(`https://dawajen.onrender.com/wajba/${userId}`);
      const responseData = await response.json();
      setData(responseData.data || []);
    } catch (error) {
      console.error("Error fetching wajba data:", error);
    }
  };

  return (
    <div className="flex flex-col  justify-center items-center gap-4">
      <h1 className="text-xl p-4 w-full bg-black text-primary c">{`سیستەمی بەڕێوەبردنی دەواجین`}</h1>
      <Link
        to="/createwajba"
        className="h-auto w-[50%]  flex p-4 bg-primary shadow-xl rounded-lg border-2 border-black gap-4  flex-col justify-center items-center"
      >
        <div className="title  text-sm c text-center">
          تۆمارکردنی وەجبەی تازە
        </div>
        <div>
          <IoAddSharp
            size={35}
            className="border-[3px] border-black rounded-md text-sec-100"
          />
        </div>
      </Link>
      <div className=" w-full h-full flex flex-col-reverse gap-4 p-4 ">
        {data.map((wajba) => (
          <Qaha
            key={wajba.wajbaId}
            title={wajba.name}
            date={wajba.createdAt}
            active={wajba.active}
            wajbaId={wajba.wajbaId}
          />
        ))}
      </div>
      <Navbar />
    </div>
  );
};
