import React, { useState, useEffect } from "react";

import Cookies from "universal-cookie";
import { IoSettingsOutline } from "react-icons/io5";
import { SideBarSettings } from "../SideBarSettings/SideBarSettings";
// import { Link } from "react-router-dom";

export const Profile = (props) => {
  const cookies = new Cookies();
  const [isOpen, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId1 = cookies.get("userId");
  const [fill, setFill] = useState([]);

  const fetchUserData = async () => {
    try {
      const userId = userId1; // Replace with the actual user ID

      const response = await fetch(
        `https://dawajen.onrender.com/user/${userId}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data.");
      }

      const data = await response.json();
      setUserData(data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleheart = (index) => {
    const updateFill = [...fill];
    updateFill[index] = !updateFill[index];
    setFill(updateFill);
  };
  return (
    <section>
      <div className="flex p-2 flex-col space-y-4 bg-primary-100 bg-primary border-b-4 border-black">
        {isOpen ? (
          <SideBarSettings
            setOpen={setOpen}
            handleLogOut={props.handleLogOut}
          />
        ) : (
          <></>
        )}

        <div className="flex w-full justify-around items-center">
          <h1 className="flex-1"></h1>
          <h1 className="flex-[3] pt-4 text-3xl  text-center">
            {userData && userData.name}
          </h1>
          <h1 className="flex-1 pt-4 text-3xl c ">
            <IoSettingsOutline
              onClick={() => {
                setOpen(!isOpen);
              }}
            />
          </h1>
        </div>
        {/* پڕۆفایل */}
        <div className="flex flex-col items-center gap-4 justify-center ">
          <div className="avatar flex-col justify-center items-center ">
            <div className="w-32 aspect-square border-4 border-black rounded-full overflow-hidden">
              {userData && (
                <img
                  src={userData.profileUrl || "https://via.placeholder.com/150"}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="bio text-xs  w-[50%] c">دەربارەی خۆت لێرە بنوسە</div>
        </div>
      </div>
      <div className="categories flex w-full px-8 ">
        <div className="Vac flex-1 p-2">
          <div className="category c w-full bg-primary px-4 py-2 rounded-lg border-2 border-black">
            دەرمان
          </div>
        </div>
        {/* <div className="birds flex-1 p-2">
          <div className="category c w-full px-4 py-2 rounded-lg border-2 border-black">
            نوسینگە
          </div>
        </div> */}
        <div className="Tools flex-1 p-2">
          <div className="category c w-full px-4 py-2 rounded-lg border-2 border-black">
            کەلووپەل
          </div>
        </div>
      </div>
  

      <div className=" w-full flex flex-col gap-4 px-8 py-2 pb-64">
        <div className="product w-full border-2 border-black rounded-xl flex ">
          <div className="image bg-black  rounded-r-xl flex-[3]">
            <img
              className=" w-full aspect-square rounded-r-xl"
              src="https://www.msd-animal-health-hub.co.uk/sites/default/files/styles/colorbox_large/public/content/image/nobilis_salenvac_t_500ml_packshot.jpg?itok=DbVs-gEa"
              alt=""
            />
          </div>
          <div className="detail flex flex-col  rounded-l-xl  p-2 flex-[2] justify-around gap-4 ">
            <div className="border-b-2 flex-1 border-black  text-sm c p-2">
              ناوی کاڵا
            </div>
            <div className="border-b-2 flex-[1] border-black  text-sm c p-2 ">
              دەربارەی کاڵا
            </div>
            <div className="border-b-2 flex-1 border-black  text-md c p-2">
              نرخی کاڵا
            </div>
          </div>
        </div>
        <div className="product w-full border-2 border-black rounded-xl flex ">
          <div className="image bg-black  rounded-r-xl flex-[3]">
            <img
              className=" w-full aspect-square rounded-r-xl"
              src="https://www.msd-animal-health-hub.co.uk/sites/default/files/styles/colorbox_large/public/content/image/nobilis_salenvac_t_500ml_packshot.jpg?itok=DbVs-gEa"
              alt=""
            />
          </div>
          <div className="detail flex flex-col  rounded-l-xl  p-2 flex-[2] justify-around gap-4 ">
            <div className="border-b-2 border-black  text-sm c p-2">
              ناوی کاڵا
            </div>
            <div className="border-b-2 border-black  text-sm c p-2">
              {" "}
              دەربارەی کاڵا
            </div>
            <div className="border-b-2 border-black  text-md c p-2">
              {" "}
              نرخی کاڵا
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
