import React from "react";
import { BsShop } from "react-icons/bs";
import { SlHome } from "react-icons/sl";
import { MdOutlineVaccines } from "react-icons/md";
// import { CiLocationOn } from "react-icons/ci";
// import { IoAddSharp } from "react-icons/io5";
// import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import { BsCpu } from "react-icons/bs";
export const Navbar = () => {
  return (
    <div className="fixed flex mx-auto bottom-0 left-0 p-4 w-full bg-black  z-50 ">
      <ul className="flex justify-around w-full items-center text-sm">
        <li className="flex flex-col justify-center items-center gap-2 flex-1">
          <Link to="/">
            <SlHome size={27} className="text-primary" />
          </Link>
          {/* سەرەکی */}
        </li>
        {/* <li>
          <Link to="/">
            <CiLocationOn size={27} className="text-sec" />
          </Link>
        </li> */}
        <li className="flex flex-col justify-center items-center gap-2 flex-1">
          <Link to="/shop">
            <BsShop size={27} className="text-primary" />
          </Link>
          {/* فرۆشگا */}
        </li>
        <li className="flex flex-col justify-center items-center gap-2 flex-1"></li>
        <li className="flex flex-col bg-black text-primary p-4 rounded-full border-2 border-white aspect-square justify-center items-center gap-2  absolute z-10 bottom-6 flex-1">
          <Link to="/system">
            <BsCpu size={35} className="text-black" />
          </Link>
          {/* سیستەم */}
        </li>
        {/* <li>
          <BsShop
            size={27}
            className="text-primary"
          />
        </li> */}
        <li className="flex flex-col justify-center items-center gap-2 flex-1">
          <Link to="/vaccineShop">
            <MdOutlineVaccines size={27} className="text-primary" />
          </Link>
          {/* فرۆشگا */}
        </li>
        <li className="flex flex-col justify-center items-center gap-2 flex-1">
          <Link to="/profile">
            <CgProfile size={27} className="text-primary" />
          </Link>
          {/* پرۆفایل */}
        </li>
      </ul>
    </div>
  );
};
