import React, { useRef, useState } from "react";
import { MdOutlineAccountCircle, MdOutlineEmail } from "react-icons/md";
import { IoLockClosedOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export const Register = () => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const usernameRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const register = async () => {
    try {
      setLoading(true);
      setError(null);

      let username = usernameRef.current;
      let email = emailRef.current;
      const password = passwordRef.current;
      email = email.toLowerCase();
      username = username.toLowerCase();
      console.log(usernameRef);
      console.log(emailRef);
      console.log(passwordRef);
      const response = await fetch("https://dawajen.onrender.com/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Redirect the user to the login page
        window.location.href = "/login";
        console.log("User registered successfully:", data);
        // Optionally, you can redirect the user or perform other actions upon successful registration
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("An error occurred during registration.");
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full bg-primary">
      <div className="container h-full flex justify-center items-center flex-col p-8">
        <div className="image w-full flex-[1] center">
          {/* <img
            src="https://i.ibb.co/xh8bWMt/rob1.png"
            alt="https://i.ibb.co/xh8bWMt/rob1.png"
            className="size-[10rem]"
          /> */}
        </div>
        <div className="signUpForm w-full flex-[6] flex flex-col justify-start items-center p-2 gap-8  ">
          <div className="welcome text-3xl ">خۆتۆمارکردن</div>

          <div className="username w-full flex justify-center items-center mr-4">
            <input
              onChange={(e) => (usernameRef.current = e.target.value)}
              type="text"
              className="w-full h-full bg-transparent -mr-9 text-center placeholder-slate-600 p-4 border-2  border-black rounded-full"
              placeholder="ناوی بەکارهێنەر"
            />
            <MdOutlineAccountCircle className="text-4xl -ml-4  " />
          </div>
          <div className="email w-full flex justify-center items-center mr-4">
            <input
              onChange={(e) => (emailRef.current = e.target.value)}
              type="text"
              className="w-full h-full bg-transparent -mr-9 text-center p-4 border-2  border-black rounded-full  placeholder-slate-600"
              placeholder="ئیمەیڵ "
            />
            <MdOutlineEmail className="text-4xl -ml-4  " />
          </div>
          <div className="username w-full flex justify-center items-center mr-4  placeholder-slate-600">
            <input
              onChange={(e) => (passwordRef.current = e.target.value)}
              type="password"
              className="w-full h-full bg-transparent -mr-9 text-center p-4 border-2  border-black rounded-full  placeholder-slate-600"
              placeholder=" وشەی نهێنی"
            />
            <IoLockClosedOutline className="text-4xl -ml-4 " />
          </div>
          <div className="username flex justify-center items-center">
            <button
              onClick={register}
              className="w-full h-full bg-white text-primary-100 text-center pt-2 pb-2 pl-4 pr-4 border-2  border-black rounded-full text-3xl"
              disabled={loading}
            >
              {loading ? "چاوەڕێ بکە" : "تۆمارکردن"}
            </button>
          </div>
          {error && (
            <div className="error-message text-red-500 text-center mt-2">
              {error}
            </div>
          )}
          <div className="alreadyHaveAnAcoount">
            <span className="">ئەکاونتت هەیە؟</span>
            <Link
              to="/login"
              className="font-bold rounded-full text-2xl "
            >
              داخڵ ببە
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
