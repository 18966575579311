import React from "react";
import { Link } from "react-router-dom";
import { IoAddSharp } from "react-icons/io5";

export const Shop = () => {
  const shop = [
    {
      profilePicture:
        "https://scontent.febl2-1.fna.fbcdn.net/v/t39.30808-6/252165401_1238069669994519_5340671368098840063_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=efb6e6&_nc_eui2=AeGdPa-DTje9d72c_FCmGuFsRYsCRkuEDvVFiwJGS4QO9dbTLx_vuJFDgEg5TnJoX-CQifNOuwcDDMpIjVvyWWAx&_nc_ohc=FZKsyDAZ-JIAX8E7IY0&_nc_ht=scontent.febl2-1.fna&oh=00_AfBPR3_WSZo8M1W96tzcRx9y1p1JdsFbhwEx-cTwQ4oRXA&oe=65E6A474",
      name: "کلینیکی د.پاڤڵ ",
      userId: "12345678",
      location: "سلێمانی",
    },
  ];
  return (
    <div className="shop h-full w-full flex justify-start items-center flex-col">
      <h1 className="text-2xl p-4 w-full bg-black text-primary c">{`فرۆشگا`}</h1>
      <div className="search flex w-full">
        <div className=" flex-1 p-2">
          <input
            placeholder="گەڕان"
            className="category text-center c w-full  px-4 py-2 rounded-lg border-2 border-black"
          ></input>
        </div>
      </div>
      <div className="addProduct px-16 ">
        <Link
          to="/addproduct"
          className="h-auto w-full   flex p-2 bg-primary shadow-xl rounded-lg border-2 border-black gap-4  justify-center items-center"
        >
          <div className="title  text-sm c text-center">
            بڵاوکردنەوەی کاڵا بۆ فرۆشتن
          </div>
          <div>
            <IoAddSharp
              size={35}
              className="border-[3px] border-black rounded-md text-sec-100"
            />
          </div>
        </Link>
      </div>
      {/* <div className="categories flex w-full">
        <div className="Vac flex-1 p-2">
          <div className="category c w-full bg-primary px-4 py-2 rounded-lg border-2 border-black">
            دەرمان
          </div>
        </div>

        <div className="Tools flex-1 p-2">
          <div className="category c w-full px-4 py-2 rounded-lg border-2 border-black">
            کەلووپەل
          </div>
        </div>
      </div> */}
      {/* <div className="shops w-full grid grid-cols-2 items-start gap-8 p-4">
        {shop.map((user, index) => (
          <Link
            to={`/user/${user.userId}`}
            className="shop w-full h-fit border-2 border-black rounded-lg flex flex-col"
            key={index}
          >
            <div className="image w-full flex-[4] rounded-t-lg bg-black">
              <img
                className="w-full aspect-video rounded-t-lg"
                src={user.profilePicture}
                alt={user.profilePicture}
              />
            </div>
            <div className="title w-full flex gap-2 justify-between items-center border-t-2 bg-primary border-black flex-col flex-[2] p-2 rounded-b-lg c">
              <span className=" text-sm w-full c">{user.name}</span>
              <span className=" text-xs w-full c">{user.location}</span>
            </div>
          </Link>
        ))}
      </div> */}
    </div>
  );
};
