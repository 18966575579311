import React from "react";
import { Link } from "react-router-dom";

export const Qaha = (props) => {
  return (
    <Link
      to={`/wajba/${props.wajbaId}`}
      className="h-auto w-full  flex p-4 bg-primary shadow-xl rounded-lg border-2 border-black"
    >
      <div className=" flex-[2] flex gap-8 ">
        <div className=" flex-[2]">
          <img
            className="rounded-xl shadow-xl border-4 border-black"
            src="https://www.aces.edu/wp-content/uploads/2022/10/Breeder-house2-scaled.jpg"
            alt=""
          />
        </div>
        <div className=" flex-[2]  flex flex-col justify-center start gap-2">
          <div className="title ">{props.title}</div>
          <div className="date ">{props.date}</div>
        </div>

        <div className="wajbaTopLeft flex flex-col text-2xl justify-center items-center  flex-[1]">
          {/* <div className="circle w-full aspect-square rounded-full bg-black"></div> */}
          {props.active ? <>🟢</> : <>🔴</>}
        </div>
      </div>
    </Link>
  );
};
