import React, { useRef, useState } from "react";
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
export const Login = (props) => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const cookies = new Cookies(null, { path: "/" });
  const handleLogin = async () => {
    try {
      setLoading(true);
      setError(null);

      let email = emailRef.current.value;
      const password = passwordRef.current.value;

      // Convert email to lowercase
      email = email.toLowerCase();

      const response = await fetch("https://dawajen.onrender.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // props.setLoggedIn(true);
        // props.setUser(data);
        cookies.set("setLoggedIn", true);
        cookies.set("user", data.data);
        cookies.set("userId", data.data.userId);
        console.log("User logged in successfully:", data.data);
        // Optionally, you can redirect the user or perform other actions upon successful login
        // Refresh the page
        window.location.reload();
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("An error occurred during login.");
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full bg-primary-300 ">
      <div className="design w-full bg-white h-[100vh]   flex flex-col">
        <div className="BootomLogin c  p-8 flex-[10] w-full ">
          <div className="loginForm w-full flex-[2] flex flex-col justify-start items-center p-2 gap-8 ">
            <div className="NotHaveAccount flex flex-col justify-center items-center text-center gap-8 ">
              <div className="icon bg-primary rounded-full c">
                <img src="https://i.ibb.co/p276nV5/chicken.png" alt="https://i.ibb.co/p276nV5/chicken.png" className=" w-32"/>
              </div>
              <span className=" text-primary-100  font-bold text-xl">
                 سیستەمی بەڕێوەبردنی دەواجین  
              </span>
            </div>
            <div className="username w-full flex justify-center items-center mr-4 ">
              <input
                ref={emailRef}
                type="text"
                className="w-full h-full bg-transparent placeholder-primary-100  -mr-9 text-center p-4 border-2 border-black border-primary-100 rounded-full"
                placeholder="ئیمەیڵ "
              />
              {/* <MdOutlineEmail className="text-4xl  text-primary-100 br" /> */}
            </div>
            <div className="password w-full flex justify-center items-center mr-4">
              <input
                ref={passwordRef}
                type="password"
                className="w-full h-full bg-transparent placeholder-primary-100 -mr-9 text-center p-4 border-2 border-black border-primary-100 rounded-full"
                placeholder=" وشەی نهێنی"
              />
              {/* <IoLockClosedOutline className="text-4xl -ml-4 text-primary-100" /> */}
            </div>
            <div className="username flex justify-center items-center">
              <button
                onClick={handleLogin}
                className="w-full bg-primary h-full text-black  text-center  px-8 py-2 border-2 border-black rounded-full text-3xl"
                disabled={loading}
              >
                {loading ? "چاوەڕێ بکە !" : "چونەژورەوە"}
              </button>
            </div>
            {error && (
              <div className="error-message text-red-500 text-center mt-2 text-red">
                {error}
              </div>
            )}
          </div>
        </div>
        {/* <div className="TopLogin bg-primary rounded-tl-full shadow-lg flex-[2] w-full c  p-8 ">
          <div className="NotHaveAccount flex flex-col justify-center items-center text-center gap-8 ">
            <span className="  font-bold text-3xl">
              خۆت تۆمار نەکردووە ؟
            </span>
            <div className=" bg-white p-1 pr-2 pl-2 rounded-lg  border-2 border-black px-4 py-2">
              <Link
                to="/signup"
                className=" text-1x1 text-primary-100  text-1xl "
              >
                خۆت تۆمار بکە
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
