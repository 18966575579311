// Import necessary icons
import { MdOutlineAccountCircle } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { LuImagePlus } from "react-icons/lu";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { IoIosArrowRoundBack } from "react-icons/io";

export const UserForm = (props) => {
  // Initialize state variables
  const cookies = new Cookies(null, { path: "/" });
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null); // New state variable to store the selected image file
  const userId = cookies.get("userId");

  useEffect(() => {
    // Fetch user data when the component mounts
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://dawajen.onrender.com/user/${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user data.");
        }
        const userData = await response.json();
        // Pre-fill input fields if user data is available
        if (userData) {
          setName(userData.data.name || "");
          setPhoneNumber(userData.data.phoneNumber || "");
          setProfileUrl(userData.data.profileUrl || "");
        }
      } catch (error) {
        console.error(error);
        // Handle error, such as showing an error message or redirecting to an error page
      }
    };

    fetchUserData();
  }, [userId]);

  // Function to handle image upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setImageFile(file); // Store the selected image file in state

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(
        "https://api.imgbb.com/1/upload?key=adb8b523364c3ba09e42cef0fb19f978",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image.");
      }

      const data = await response.json();
      setProfileUrl(data.data.display_url);
    } catch (error) {
      console.error(error);
      setError("An error occurred while uploading the image.");
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://dawajen.onrender.com/updateUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name.toLowerCase(),
            phoneNumber: phoneNumber.toLowerCase(),
            profileUrl: profileUrl,
            userId: userId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update user.");
      }
      window.location.href = "/";
    } catch (error) {
      console.error(error);
      setError("An error occurred while updating user.");
    } finally {
      setLoading(false);
    }
  };
  const handleGoBack = () => {
    // Go back one step
    window.history.back();
  };

  return (
    <div className="w-full h-full bg-primary-300  bg-primary">
      <div className="container h-full flex justify-center items-center flex-col p-8">
      <div className="flex justify-between items-center w-full">
        <div
          onClick={handleGoBack}
          className="backIcon  flex-[1] p-2 h-full hover:cursor-pointer center  text-3xl pt-4"
        >
            <IoIosArrowRoundBack />
         
        </div>
          <h1 className="flex-1 pt-4 text-2xl  font-kurdish_043 text-center">
            ئەکاونتەکەم
          </h1>
          <h1 className="flex-1 pt-4 text-3xl  center ">
          
          </h1>
        </div>
        <div className=" w-full h-full flex-[2] flex flex-col justify-center items-center p-2 gap-8 ">
          <form onSubmit={handleSubmit} className="flex w-full flex-col gap-8">
            <div className="username w-full flex justify-center items-center mr-4">
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                className="w-full h-full bg-transparent placeholder-white -mr-9 text-center p-4 border-2 border-black  rounded-full"
                placeholder="ناوی سیانی"
              />
              <MdOutlineAccountCircle className="text-4xl -ml-4 " />
            </div>
            <div className="email w-full flex justify-center items-center mr-4">
              <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                type="text"
                className="w-full h-full bg-transparent placeholder-white -mr-9 text-center p-4 border-2 border-black  rounded-full"
                placeholder="ژمارە موبایل"
              />
              <BsTelephone className="text-4xl -ml-4 " />
            </div>
            <div className="profileUrl flex w-full  gap-16 justify-center items-center mr-4">
              <input
                type="file"
                onChange={handleImageUpload}
                accept="image/*"
                className="hidden"
                id="imageUpload"
              />

              {/* Preview of the selected image */}
              {imageFile && (
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="Preview"
                  className="w-32 h-32 object-cover rounded-full shadow-lg mt-4"
                />
              )}

              {/* If there's no selected image but there's a profileUrl, display the already selected image */}
              {profileUrl && !imageFile && (
                <img
                  src={profileUrl}
                  alt="Preview"
                  className="w-32 h-32 object-cover rounded-full shadow-lg mt-4"
                />
              )}

              {/* Image upload icon */}
              <label
                htmlFor="imageUpload"
                className="  cursor-pointer bg-primary-500 rounded-lg p-2"
              >
                <LuImagePlus className="text-4xl " />
              </label>
            </div>

            <div className="username flex justify-center items-center">
              <button
                type="submit"
                className="w-full h-full bg-white text-primary-100 text-center pt-2 pb-2 pl-4 pr-4 border-2 border-black  rounded-full text-3xl"
                disabled={loading}
              >
                {loading ? "چاوەڕێ بکە !" : "نوێکردنەوە"}
              </button>
            </div>
            
          </form>
         
          {error && (
            <div className="error-message text-red-500 text-center mt-2">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
