import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";

import "./App.css";
import { Login } from "./Pages/Login/Login";
import { Register } from "./Pages/Register/Register";
import { Home } from "./Pages/Home/Home";
import { Navbar } from "./Components/Navbar/Navbar";
import { UserForm } from "./Pages/UserForm/UserForm";
import { CreateWajba } from "./Components/CreateWajba/CreateWajba";
import { Wajba } from "../src/Components/Wajba/Wajba";
import { Calender } from "./Components/Calender/Calender";
import { Profile } from "./Components/Profile.jsx/Profile";
import { Shop } from "./Components/Shop/Shop";
import { Main } from "./Components/Main/Main";
import { AddProduct } from "./Components/AddProduct/AddProduct";
import { VacShop } from "./Components/VacShop/VacShop";

function App() {
  const cookies = new Cookies(null, { path: "/" });

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(cookies.get("userId"));
  useEffect(() => {
    if (cookies.get("setLoggedIn")) {
      setLoggedIn(true);
      const userData = cookies.get("user");
      setUser(cookies.get("userId"));
      // console.log(userData);
      // console.log(user);
    }
  }, []);
  const handleLogOut = () => {
    setLoggedIn(false);
    setUser(null);
    cookies.set("setLoggedIn", false);
    cookies.set("user", null);
  };
  return (
    <div className="h-screen">
      <BrowserRouter>
        <Routes>
          <Route
            path="/signup"
            element={isLoggedIn ? <Navigate to="/" /> : <Register />}
          />

          <Route
            path="/profile"
            element={
              isLoggedIn ? (
                <>
                  <Profile handleLogOut={handleLogOut} />
                  <Navbar />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/shop"
            element={
              isLoggedIn ? (
                <>
                  <Shop />
                  <Navbar />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/vaccineShop"
            element={
              isLoggedIn ? (
                <>
                  <VacShop />
                  <Navbar />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="/createwajba" element={<CreateWajba />} />
          <Route path="/wajba/:wajbaId" element={<Wajba />} />
          <Route path="/wajba/:wajbaId/calender" element={<Calender />} />

          <Route
            path="/login"
            element={
              isLoggedIn ? (
                <Navigate to="/" />
              ) : (
                <Login setLoggedIn={setLoggedIn} setUser={setUser} />
              )
            }
          />
          <Route
            path="/system"
            element={
              isLoggedIn ? (
                <>
                  <Home />

                  <Navbar />

                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/addproduct"
            element={
              isLoggedIn ? (
                <>
                  <AddProduct />
                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <>
                  <Main />
                  <Navbar />

                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              isLoggedIn ? (
                <>
                  {/* <Profile /> */}
                  <div onClick={handleLogOut}>Logout</div>
                  <Navbar />
                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/post"
            element={
              isLoggedIn ? (
                <>
                  {/* <Post /> */}
                  <Navbar />
                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/settings"
            element={
              isLoggedIn ? (
                <>
                  {/* <Post /> */}
                  <UserForm />
                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
