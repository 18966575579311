import React, { useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";
export const AddProduct = () => {
  const [type, setType] = useState("دەرمان");
  const [title, setTitle] = useState("");
  const [about, setAbout] = useState("");
  const [price, setPrice] = useState("");
  return (
    <div className="addProduct w-full flex flex-col justify-start items-center">
      <div className="top bg-black text-primary w-full flex justify-around items-center h-full p-2">
        <div className=" flex-1 "></div>
        <div className="title text-xl flex-[5] c ">تۆمارکردنی کاڵا </div>
        <Link to={"/"} className="flex-1 text-center c text-3xl">
          <MdOutlineArrowBack />
        </Link>
      </div>
      <div className="content w-full flex flex-col gap-4  px-8 py-4">
        <div className="type w-full flex items-center">
          <span className="flex-[2] text-center p-2 border-2 rounded-r-lg border-l-0 border-black ">
            جۆری کاڵا
          </span>
          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            name="age"
            id="age"
            className="flex-[3] p-4 text-center border-2 rounded-lg border-black  "
          >
            <option value={`دەرمان`}>
              <span>{`دەرمان`} </span>
            </option>
            <option value={`کەلووپەل`}>
              <span>{`کەلووپەل`} </span>
            </option>
          </select>
        </div>
        <div className="title w-full flex items-center">
          <span className="flex-[2] text-center p-2 border-2 rounded-r-lg border-l-0 border-black">
            ناوی کاڵا
          </span>
          <input
            value={title}
            placeholder="ناوی کاڵاکەت لێرە بنوسە"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            name="age"
            id="age"
            className="flex-[3] p-4 text-center border-2 rounded-lg border-black"
          ></input>
        </div>
        <div className="title w-full flex items-center">
          <span className="flex-[2] text-center p-2 border-2 rounded-r-lg border-l-0 border-black">
            دەربارەی کاڵا
          </span>
          <textarea
            value={about}
            placeholder="دەربارەی کاڵاکەت لێرە بنوسە"
            onChange={(e) => {
              setAbout(e.target.value);
            }}
            name="age"
            id="age"
            className="flex-[3] p-2 text-center border-2 rounded-lg border-black text-wrap"
          ></textarea>
        </div>
        <div className="title w-full flex items-center">
          <span className="flex-[2] text-center p-2 border-2 rounded-r-lg border-l-0 border-black">
            نرخی کاڵا
          </span>
          <input
            value={price}
            placeholder="نرخی کاڵاکەت لێرە بنوسە"
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            name="age"
            id="age"
            className="flex-[3] p-4 text-center border-2 rounded-lg border-black"
          ></input>
        </div>
        <div className=" w-full flex items-center">

        </div>
        <div className="addProduct  px-16 ">
          <div className="h-auto w-full   flex p-4 bg-primary shadow-xl rounded-lg border-2 border-black gap-4  flex-col justify-center items-center">
            <div className="title  text-sm c text-center">تۆمارکردنی کاڵا</div>
          </div>
        </div>
      </div>
    </div>
  );
};
