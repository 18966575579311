import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Link, useParams } from "react-router-dom";
import { Stats } from "../Stats/Stats";
import { SlCalender } from "react-icons/sl";
import { MdOutlineArrowBack } from "react-icons/md";

export const Wajba = () => {
  const cookies = new Cookies();

  let userId = cookies.get("userId");

  const { wajbaId } = useParams();
  const [wajbaData, setWajbaData] = useState(null);
  const [chickenAge, setChickenAge] = useState("0");

  useEffect(() => {
    const fetchWajbaData = async () => {
      try {
        const response = await fetch(
          `https://dawajen.onrender.com/wajba/${userId}/${wajbaId}`
        );
        const data = await response.json();
        setWajbaData(data.data);

        // Calculate chicken age
        const createdAt = new Date(data.data.createdAt);
        const currentDate = new Date();
        const ageInDays = Math.floor(
          (currentDate - createdAt) / (1000 * 60 * 60 * 24)
        );
        const finalAge = parseInt(ageInDays) + parseInt(data.data.age);
        setChickenAge(finalAge);
      } catch (error) {
        console.error("Error fetching wajba data:", error);
      }
    };

    fetchWajbaData();
  }, [userId, wajbaId]);

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://dawajen.onrender.com/wajba/${wajbaId}`,
        {
          method: "DELETE"
        }
      );
      if (response.ok) {
        window.location.href = "/";
      } else {
        console.error("Failed to delete wajba object.");
      }
    } catch (error) {
      console.error("Error deleting wajba object:", error);
    }
  };

  return (
    <div className="w-full h-auto  flex  flex-col p-4 gap-4 pb-24">
      {wajbaData ? (
        <div className="wajbaTop h-auto w-full  flex p-2">
          <div className="wajbaTopRight flex-[3] flex gap-8 ">
            <div className="wajbaImg flex-[1]">
              <img
                className="rounded-xl shadow-xl"
                src="https://www.aces.edu/wp-content/uploads/2022/10/Breeder-house2-scaled.jpg"
                alt=""
              />
            </div>
            <div className="wajbaTitle flex-[2]  flex flex-col justify-center start gap-2">
              <div className="title">{wajbaData.name}</div>
              <div className="date">{wajbaData.createdAt}</div>
            </div>
          </div>
          <Link
            to={"/"}
            className="wajbaTopLeft flex-1  flex flex-col text-4xl justify-center items-end  transition-all ease-in-out active:scale-110"
          >
            <MdOutlineArrowBack />
          </Link>
        </div>
      ) : (
        <div>Loading...</div>
      )}
      {wajbaData && (
        <>
          <Stats
            title={"ژمارەی مریشک"}
            number={wajbaData.number - wajbaData.data.dead.value}
            type={"دانە"}
            edit={false}
          />

          <div className="grid grid-cols-2 gap-4 justify-items-center ">
            <Link
              to={`/wajba/${wajbaId}/calender`}
              className="stats h-auto w-full p-4 flex flex-col justify-start items-center text-center   gap-2 bg-primary shadow-lg rounded-lg text-xl"
            >
              <span>بینینی خشتەی گشتی</span>
              <SlCalender className="text-6xl" />
            </Link>
            <div className="stats h-auto w-full p-2 flex flex-col justify-start items-center   gap-1 bg-primary shadow-lg rounded-lg">
              <div className="statsTop w-full h-auto   flex gap-2 p-2">
                <div className="statsTitle flex-[3] text-lg  flex justify-center items-center ">
                  {`تەمەنی مریشک`}
                </div>
              </div>
              <div className="statsCenter w-full h-auto  ">
                <div className="statsNumber text-center  p-2  text-4xl">
                  {chickenAge}
                </div>
                <div className="statsType text-center  p-2 ">{"رۆژ"}</div>
              </div>
            </div>
            {Object.entries(wajbaData.data).map(([key, item], index) => (
              <Stats
                edit={true}
                title={item.name}
                number={item.value}
                type={item.type}
                key={index}
                dataKey={key} // Pass the dataKey here
              />
            ))}
          </div>
          <div className="tools flex w-full h-auto mt-16 p-2 gap-4">
            <div
              className="delete border-4 border-red-500 w-full p-4 text-red c rounded-lg cursor-pointer"
              onClick={handleDelete}
            >
              ڕەشکردنەوەی وەجبە
            </div>
            <div className="archieve border-4  border-black w-full p-4  c rounded-lg">
              ئەرشیفکردنی وەجبە
            </div>
          </div>
        </>
      )}
    </div>
  );
};
