import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { BsPlusSquareFill } from "react-icons/bs";
import ReactLoading from "react-loading";
import { MdFileDownloadDone } from "react-icons/md";

export const Stats = (props) => {
  const [isUploading, setUploading] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  const [edit, setEdit] = useState(false);

  const [updatedName, setUpdatedName] = useState(props.title);
  const [todayValue, setTodayValue] = useState("0");
  const [updatedValue, setUpdatedValue] = useState(props.number);
  const [updatedType, setUpdatedType] = useState(props.type);
  const { wajbaId } = useParams();

  const handleUpdate = async () => {
    let x = parseInt(updatedValue) + parseInt(todayValue);
    try {
      setUploading(true);
      // Ensure updatedValue is initialized properly

      const response = await fetch(
        `https://dawajen.onrender.com/updatedata/${wajbaId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            key: props.dataKey, // Assuming props.dataKey holds the key of the data to update
            name: updatedName,
            value: x,
            todayValue: parseInt(todayValue),
            type: updatedType,
          }),
        }
      );

      setUploading(false);
      setUploaded(true);
      const data = await response.json();
      console.log(data); // Handle response from server
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const handleEditClick = () => {
    setEdit(!edit);
    // Scroll to top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="stats h-auto w-full p-2 flex flex-col justify-start items-center   gap-1 bg-primary shadow-lg rounded-lg">
      {isUploading ? (
        <div className="uploading absolute top-0 right-0 w-full h-[300vh] backdrop-blur-lg z-[99999] flex justify-center pt-80">
          <div className="box w-32 h-32 rounded-lg c  ">
            <ReactLoading
              type="spin"
              height={"40%"}
              width={"40%"}
              color="#5b47d6"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {isUploaded ? (
        <div className="uploading absolute w-full top-0 right-0 h-[300vh] backdrop-blur-lg z-[99999] flex justify-center pt-80">
          <div className="box w-80 border-2 border-black  h-80 rounded-lg  border-primary-100 c font-kurdish_043 text-2xl text-center text-primary-100 font-bold bg-white flex-col gap-8 z-50 ">
            بە سەرکەوتووی تۆمارکرا
            <MdFileDownloadDone className="text-8xl" />
            <Link
              to={`/wajba/${wajbaId}`}
              onClick={() => window.location.reload()}
              className="tohome text-xl px-4 py-2 bg-primary-100 text-white bg-black rounded-md"
            >
              کلیک لێرە بکە
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
      {edit ? (
        <div className=" w-full h-[200vh] flex flex-col justify-start items-center pt-64 absolute backdrop-blur-md top-0  right-0 gap-8">
          <button onClick={() => setEdit(!edit)} className=" text-4xl">
            X
          </button>
          {props.dataKey !== "weight" ? (
            <>
              <div className="title">{`ژمارەی ${updatedName}  داخڵ بکە`} </div>
              <input
                className=" text-center"
                type="text"
                value={todayValue}
                onChange={(e) => {
                  setTodayValue(e.target.value);
                }}
              />
            </>
          ) : (
            <>
              <div className="title">{`کۆی گشتی ${updatedName} `} </div>
              <input
                className=" text-center"
                type="text"
                value={updatedValue}
                onChange={(e) => setUpdatedValue(e.target.value)}
              />
            </>
          )}

          {/* <div className="title">ناوی زانیاری </div>
          <input
            className=" text-center"
            type="text"
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
          />
          <div className="title">{`کۆی گشتی ${updatedName} `} </div>
          <input
            className=" text-center"
            type="text"
            value={updatedValue}
            onChange={(e) => setUpdatedValue(e.target.value)}
          />
          <div className="title">جۆری زانیاری </div>
          <input
            className=" text-center"
            type="text"
            value={updatedType}
            onChange={(e) => setUpdatedType(e.target.value)}
          /> */}
          <button
            className=" border-2 border-black px-8 py-2 rounded-lg"
            onClick={handleUpdate}
          >
            دڵنیابوونەوە
          </button>
        </div>
      ) : (
        <> </>
      )}
      <div className="statsTop w-full h-auto   flex gap-2 p-2">
        <div className="statsTitle flex-[3] text-sm  flex justify-start items-center ">
          {`کۆی گشتی ${props.title}`}
        </div>
        <div className="statsEdit flex-[1] text-left">
          {props.edit ? (
            <BsPlusSquareFill
              className="text-3xl cursor-pointer"
              onClick={handleEditClick}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="statsCenter w-full h-auto  ">
        <div className="statsNumber text-center  p-2  text-4xl">
          {props.number}
        </div>
        <div className="statsType text-center  p-2 ">{props.type}</div>
      </div>
    </div>
  );
};
