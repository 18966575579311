import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

export const Main = () => {
  return (
    <div className="main flex flex-col items-center justify-start w-full">
      <h1 className="text-xl p-4 w-full bg-black text-primary c">{` سەرەکی `}</h1>
      <div className="hero w-full  p-4 ">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          // navigation={true}
          modules={[Autoplay, Pagination]}
          // onAutoplayTimeLeft={onAutoplayTimeLeft}
          className="mySwiper w-full aspect-video"
        >
          <SwiperSlide className=" w-full h-full border-2 border-black bg-primary rounded-xl ">
            <div className=" w-full h-full  flex flex-col justify-center items-center gap-4 text-xl">
              <span>رێکلامەکەت لێرە دابنێ </span>
              <span>07503825206</span>
            </div>
          </SwiperSlide>
          <SwiperSlide className=" w-full h-full border-2 border-black bg-primary rounded-xl ">
            <div className=" w-full h-full  flex flex-col justify-center items-center gap-4 text-xl">
              <span>رێکلامەکەت لێرە دابنێ </span>
              <span>07503825206</span>
            </div>
          </SwiperSlide>
          <SwiperSlide className=" w-full h-full border-2 border-black bg-primary rounded-xl ">
            <div className=" w-full h-full  flex flex-col justify-center items-center gap-4 text-xl">
              <span>رێکلامەکەت لێرە دابنێ </span>
              <span>07503825206</span>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <Link to={"/system"} className="reminder w-full  p-4">
        <div className=" w-full h-full bg-primary border-2 border-black rounded-md c p-2">
          زانیاریەکانی ئەمرۆت داخڵی سیستەم کردوو ؟
        </div>
      </Link>
    </div>
  );
};
