import React, { useState } from "react";
import { MdDelete, MdOutlineArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import ReactLoading from "react-loading";

import { MdFileDownloadDone } from "react-icons/md";

const { numberToArabic } = require("number-to-arabic");

export const CreateWajba = () => {
  const [isUploading, setUploading] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const [name, setName] = useState("");
  const [number, setNumber] = useState(0);
  const [age, setAge] = useState(1);
  const [formData, setFormData] = useState({
    money: {
      name: "مەسرووف",
      value: 0,
      type: "دینار",
    },
    dead: {
      name: "مردوو",
      value: 0,
      type: "دانە",
    },
    weight: {
      name: "وەزن",
      value: 0,
      type: "گرام",
    },
    gaz: {
      name: "گاز",
      value: 0,
      type: "ڵیتر",
    },
    vitamen: {
      name: "ڤیتامین",
      value: 0,
      type: "دەبە",
    },
    calcium: {
      name: "کالسیۆم",
      value: 0,
      type: "دەبە",
    },
    halaf1: {
      name: "حەلەفی جۆری ١",
      value: 0,
      type: "فەردە",
    },
    halaf2: {
      name: "حەلەفی جۆری ٢",
      value: 0,
      type: "فەردە",
    },
    halaf3: {
      name: "حەلەفی جۆری ٣",
      value: 0,
      type: "فەردە",
    },
  });
  const [newFieldName, setNewFieldName] = useState("");
  const [newFieldType, setNewFieldType] = useState("");

  const handleDelete = (key) => {
    const updatedFormData = { ...formData };
    delete updatedFormData[key];
    setFormData(updatedFormData);
  };

  const renderFields = () => {
    return Object.entries(formData).map(([key, field], index) => (
      <div
        className="field  w-full p-2 gap-2 flex border-2 rounded-xl"
        key={key}
      >
        <div className="index flex-[1] c  p-2">{numberToArabic(index + 1)}</div>
        <div className="name flex-[6] text-right  p-2">{field.name}</div>
        <div className="value flex-[6] text-right  p-2">{field.value}</div>
        <div className="type flex-[6] text-right  p-2">{field.type}</div>
        <div
          className="action flex-[1]  p-2 c text-xl  text-red"
          onClick={() => handleDelete(key)}
        >
          <MdDelete />
        </div>
      </div>
    ));
  };

  const handleAdd = () => {
    if (newFieldName.trim() !== "") {
      const key = `customField${Object.keys(formData).length}`;
      setFormData({
        ...formData,
        [key]: {
          name: newFieldName,
          value: 0,
          type: newFieldType,
        },
      });
      setNewFieldName("");
      setNewFieldType("");
    }
  };

  const handleUpload = async () => {
    try {
      setUploading(true);
      const response = await fetch(`https://dawajen.onrender.com/wajba/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, age, number, formData }),
      });
      if (response.ok) {
        setUploading(false);
        setUploaded(true);
        console.log("Wajba successfully created!");
      } else {
        console.error("Failed to create Wajba.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className=" w-full h-full absolute  flex justify-start items-center gap-8 flex-col ">
      {isUploading && (
        <div className="uploading absolute w-full h-[300vh] backdrop-blur-lg z-[99999] flex justify-center pt-80">
          <div className="box w-32 h-32 rounded-lg c  ">
            <ReactLoading
              type="spin"
              height={"40%"}
              width={"40%"}
              color="#5b47d6"
            />
          </div>
        </div>
      )}
      {isUploaded && (
        <div className="uploading absolute w-full h-[300vh] backdrop-blur-lg z-[99999] flex justify-center pt-80">
          <div className="box w-80 h-80 rounded-lg border-2 border-primary-100 c font-kurdish_043 text-2xl text-center text-primary-100 font-bold bg-white flex-col gap-8 ">
            بە سەرکەوتووی دانرا
            <MdFileDownloadDone className="text-8xl" />
            <Link
              to={"/"}
              className="tohome text-xl px-4 py-2 bg-primary-100 text-white bg-black rounded-md"
            >
              گەڕانەوە بۆ بەشی سەرەکی
            </Link>
          </div>
        </div>
      )}
      <div className="top bg-black text-primary w-full flex justify-around items-center h-full p-2">
        <div className=" flex-1 "></div>
        <div className="title text-3xl flex-[5]">تۆمارکردنی وەجبەی تازە</div>
        <Link to={"/"} className="flex-1 text-center c text-3xl">
          <MdOutlineArrowBack />
        </Link>
      </div>

      <div className="content flex flex-col gap-4 p-4">
        <div className="name w-full flex">
          <span className="flex-[2] text-center p-2 border-black border-2 rounded-r-lg border-l-0">
            ناوی قاعە
          </span>
          <input
            value={name}
            type="text"
            placeholder="ناوی قاعەکەت لێرە بنوسە"
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="flex-[3] p-2 text-center border-2 rounded-l-lg border-black"
          />
        </div>
        <div className="name w-full flex">
          <span className="flex-[2] text-center p-2 border-black border-2 rounded-r-lg border-l-0">
            ژمارەی جوجکە
          </span>
          <input
            type="text"
            placeholder=" ژمارەی جوجکەکەت  لێرە بنوسە"
            onChange={(e) => {
              setNumber(e.target.value);
            }}
            className="flex-[3] p-2 text-center border-2 rounded-l-lg border-black"
          />
        </div>
        <div className="age w-full flex">
          <span className="flex-[2] text-center p-2 border-2 rounded-r-lg border-l-0 border-black">
            تەمەنی جوجکەکە
          </span>
          <select
            value={age}
            onChange={(e) => {
              setAge(e.target.value);
            }}
            name="age"
            id="age"
            className="flex-[3] p-2 text-center border-2 rounded-l-lg border-black"
          >
            {Array.from({ length: 19 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                <span>{numberToArabic(i + 1)}</span>
                <span>{`    ڕۆژ`} </span>
              </option>
            ))}
          </select>
        </div>
        <div className="fields w-full  flex flex-col gap-2 pb-64">
          <div className="title text-lg text-center">
            ئەو زانیاریانە هەڵبژێرە کە سیستەمەکەمان بۆت بەڕێوە ببات
          </div>

          {renderFields()}
          <div className="addField  rounded-xl flex w-full">
            <div className="input flex-[6] border-black border-2 p-2 text-center rounded-r-xl flex gap-2 ">
              <input
                type="text"
                className="  w-full h-full py-1 px-4 border-l-4 border-black"
                placeholder="ناوی زانیاری"
                onChange={(e) => {
                  setNewFieldName(e.target.value);
                }}
              />
              <input
                type="text"
                className=" w-full h-full  py-1 px-4"
                placeholder="جۆری زانیاری"
                onChange={(e) => {
                  setNewFieldType(e.target.value);
                }}
              />
            </div>

            <button
              className="flex-[1] bg-black text-white rounded-l-2xl p-2"
              onClick={handleAdd}
            >
              زیادکردن
            </button>
          </div>
        </div>
      </div>

      <div className="post fixed c z-10 bottom-0 w-full h-16 rounded-t-xl border-t-2 bg-black border-black backdrop-blur-lg">
        <button
          className=" p-2 border-2 border-primary bg-black text-primary c rounded-md active:scale-110 transition-all ease-in-out"
          onClick={handleUpload}
        >
          تۆمارکردن
        </button>
      </div>
    </div>
  );
};
