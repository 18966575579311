import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { LuShare } from "react-icons/lu";
import { MdOutlineArrowBack } from "react-icons/md";

export const Calender = () => {
  const { wajbaId } = useParams();
  const [wajbaObject, setWajbaObject] = useState(null);
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const [currentUrl, setCurrentUrl] = useState("");
  const [editableTable, setEditableTable] = useState(null);

  useEffect(() => {
    setCurrentUrl(window.location.href);
    const fetchWajbaObject = async () => {
      try {
        const response = await fetch(
          `https://dawajen.onrender.com/wajba/${userId}/${wajbaId}`
        );
        if (response.ok) {
          const data = await response.json();
          setWajbaObject(data.data);
          setEditableTable(data.data); // Initialize editableTable with fetched data
        } else {
          console.error("Failed to fetch wajba object.");
        }
      } catch (error) {
        console.error("Error fetching wajba object:", error);
      }
    };

    fetchWajbaObject();
  }, [userId, wajbaId]);

  const handleCellChange = (e, rowIndex, header) => {
    const updatedTable = [...editableTable.timetable];
    updatedTable[rowIndex].values[header].value = e.target.value;
    setEditableTable({ ...editableTable, timetable: updatedTable });
  };

  const saveTable = () => {
    console.log(editableTable.timetable); // Log the updated editableTable object
  };

  const renderTimetable = () => {
    if (!editableTable || !editableTable.timetable) {
      return <div>هیچ خشتەیەک نەدۆزرایەوە</div>;
    }

    // Extracting headers from the first entry
    const headers = Object.keys(editableTable.timetable[0]?.values || {});

    // Mapping headers to their corresponding names
    const headerNames = headers.map(
      (key) => editableTable.timetable[0]?.values[key].name
    );

    return (
      <div className="w-full h-full overflow-x-scroll text-[0.5rem]">
        <button
          onClick={saveTable}
          className=" px-4 py-2 bg-primary border-2 border-black m-4"
        >
          تۆمارکردن
        </button>

        <table className="table-auto border-collapse border border-black">
          <thead>
            <tr className="bg-gray-200">
              <th className="bg-black text-primary px-4 py-2">رۆژ</th>
              <th className="border bg-black text-white border-white border-b-0 px-4 py-2 min-w-24">
                بەروار
              </th>
              <th className="border bg-black text-white border-white border-b-0 px-4 py-2 min-w-8">
                تەمەنی مریشک
              </th>
              {headerNames.map((header, index) => (
                <th
                  key={index}
                  className="border bg-black text-white border-white border-b-0 px-4 py-2 min-w-8"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {editableTable.timetable.map((entry, rowIndex) => (
              <tr key={rowIndex}>
                <td className="border bg-black text-white border-white px-4 py-2 text-center">
                  <span className="center">{rowIndex + 1}</span>
                </td>
                <td className="border border-black px-4 py-2 text-center">
                  <input
                    type="text"
                    value={entry.date}
                    onChange={(e) => handleCellChange(e, rowIndex, "date")}
                    className="c  text-center"
                  />
                </td>
                <td className="border border-black px-4 py-2 text-center flex flex-col">
                  <span className="center">
                    {parseInt(editableTable.age) + parseInt(rowIndex)}
                  </span>
                  <span className="center">ڕۆژ</span>
                </td>
                {headers.map((header, colIndex) => (
                  <td key={colIndex} className="border border-black px-4 py-2">
                    <div className="flex flex-col justify-center items-center">
                      <input
                        type="text"
                        className="c  text-center"
                        value={
                          entry.values[header] ? entry.values[header].value : ""
                        }
                        onChange={(e) => handleCellChange(e, rowIndex, header)}
                      />
                      <span>
                        {entry.values[header] ? entry.values[header].type : ""}
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="topCalendar w-full h-16 bg-black flex justify-center items-center ">
        <div
          className="print flex-[1] 0 text-primary c text-2xl transition-all ease-in-out active:scale-110 hover:cursor-pointer"
          onClick={() => navigator.clipboard.writeText(currentUrl)}
        >
          <LuShare />
        </div>
        <div className="title text-primary flex-[3] 0 text-center">
          {editableTable && editableTable.name}
        </div>
        <Link
          to={"/"}
          className="backButton flex-[1] 0 text-primary c text-2xl transition-all ease-in-out active:scale-110"
        >
          <MdOutlineArrowBack />
        </Link>
      </div>
      {renderTimetable()}
    </div>
  );
};
